<template>
    <ion-app class="app-container">
        <ion-router-outlet />
        <div class="version-label">
            version: {{ version }}
            <template v-if="userName"> | user: {{ userName }} {{ paddedUserId }} | {{ isSubscribed }}</template>
        </div>
    </ion-app>
</template>

<script>
import pkg from '~/package.json'
import audioMixin from '~/mixins/audioMixin';
import { useUserStore } from '@/store/userStore'
import { useGuestStore } from '@/store/guestStore'

export default {
    mixins: [audioMixin],
    data() {
        return {
            version: pkg.version,
            userName: null,
            userId: null,
            isSubscribed: false,
            paddedUserId: null
        };
    },
    created() {
        const uStore = useUserStore();
        const gStore = useGuestStore();

        this.userName = uStore.user?.username || 'guest';
        this.userId = uStore.user?.id || gStore.guestID;
        if (this.userId != null) {
            this.paddedUserId = this.userId.toString().padStart(10, "0");
        }
    },
    mounted() {
        const uStore = useUserStore();
        const gStore = useGuestStore();

        this.$watch(
            () => uStore.user,
            (newUser) => {

                this.userName = newUser?.username || 'guest';
                this.userId = newUser?.id || gStore.guestID;
                this.isSubscribed = newUser?.is_subscribed || false;
                if (this.userId != null) {
                    this.paddedUserId = this.userId.toString().padStart(10, "0");
                }
            },
            { deep: true }
        );
    }
};
</script>

<style scoped>
.app-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.version-label {
    position: absolute;
    bottom: .75rem;
    margin-left: .75rem;
    background: none;
    color: #555555;
    font-size: 0.8em;
    z-index: 9999;
}

[dir="rtl"] .version-label {
    margin-left: 0;
    margin-right: .75rem;
}
</style>
